import validator from "@rjsf/validator-ajv8";
import React from "react";
import Form from "@rjsf/chakra-ui";
import { VStack, SimpleGrid, GridItem, Stack, Button, HStack, useToast } from "@chakra-ui/react";
import { applyi18nToSchema, generateUiSchema, getReadableApiError, useTranslation } from "./lib/utils";
import AudioRecorderWidget from "./components/audio-recorder-widget";
import PoweredBy from "./components/powered-by";
import { transformErrors } from "./lib/form-utils";
import T from "./renderer/local-text";
import { MdOutlineFileUpload } from "react-icons/md";

// const {
//     widgets: { FileWidget },
// } = Widgets // To get widgets from core

// function CustomFileWidget(props: WidgetProps) {
//     return <FileWidget {...props} />;
// }

const ObjectFieldTemplate = ({ properties, uiSchema }) => {
    return (<VStack align='stretch' mt='4'>
        {uiSchema?.['ui:grid'] ? (
            <SimpleGrid columns={12} gap={{ base: 2 }}>
                {Array.isArray(uiSchema['ui:grid']) ?
                    uiSchema['ui:grid'].map(([uiGridItemName, uiGridItemSpan]) => {
                        let element = properties.find((p => p.name === uiGridItemName))
                        if (element) {
                            return <GridItem key={element.name} colSpan={uiGridItemSpan}>
                                {element.content}
                            </GridItem>
                        }
                        return ""
                    }) : properties.map((element) => (
                        <GridItem key={element.name} colSpan={12}>
                            {element.content}
                        </GridItem>
                    ))}
            </SimpleGrid>) :
            <VStack spacing={4} align="stretch">
                {properties.map((prop) => prop.content)}
            </VStack>
        }
    </VStack>
    );
}

const FormBuilt = ({ jsonSchema, onSubmit, isPreview, buttonText, buttonColor, buttonOnSameRow, isDisabled }) => {
    const t = useTranslation();
    const submitFormRef = React.useRef();
    const [schema, setSchema] = React.useState(null);
    const [formData, setFormData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [defaultWidgets, setDefaultWidgets] = React.useState(null);
    const toast = useToast();

    React.useEffect(() => {
        const loadWidgets = async () => {
            const rjsfCore = await import("@rjsf/core");
            const { getDefaultRegistry } = rjsfCore;
            setDefaultWidgets(getDefaultRegistry().widgets);
        };
        loadWidgets();
    }, []);


    React.useEffect(() => {
        const uiSchema = generateUiSchema(jsonSchema)
        uiSchema['ui:options'] = { 'label': false }
        applyi18nToSchema(jsonSchema, t)
        setSchema({ schema: jsonSchema, uiSchema });
    }, [jsonSchema]);

    const CustomFileWidget = defaultWidgets ? ({ id, ...props }) => {
        const inputRef = React.useRef()
        const { FileWidget: DefaultFileWidget } = defaultWidgets

        React.useEffect(() => {
            inputRef.current = document.getElementById(id);
        }, [id]); // Runs when id changes

        const handleClick = () => {
            if (inputRef.current) {
                inputRef.current.click();
            }
        };

        // custom props after {...props}, order matters
        return <>
            <DefaultFileWidget {...props} id={id} />
            <Button leftIcon={<MdOutlineFileUpload />} onClick={handleClick}>
                <T>filepicker:select</T>
            </Button>
        </>
    } : null

    if (!schema || !CustomFileWidget) {
        return ""
    }

    return <Stack
        bg='transparent'
        w='full'
        direction={{ base: 'column', sm: buttonOnSameRow ? 'row' : 'column' }}
        align={{
            base: 'stretch',
            sm: buttonOnSameRow ? 'end' : 'stretch'
        }}
        justify='stretch'
    >
        <Form
            transformErrors={transformErrors}
            // readonly={isPreview}
            tagName={isPreview ? 'div' : undefined}
            schema={schema.schema}
            onChange={({ formData }) => setFormData(formData)}
            formData={formData}
            uiSchema={schema.uiSchema}
            validator={validator}
            disabled={isDisabled}
            onSubmit={(formData, e) => {
                e.preventDefault();
                if (!isPreview) {
                    toast({
                        title: <T>toast:title:submitting</T>,
                        status: "info",
                        duration: null,
                        isClosable: false,
                    })
                    setIsLoading(true);
                    return onSubmit(formData).then(() => {
                        toast.closeAll();
                        toast({
                            status: "info",
                            title: <T>toast:title:submitted</T>,
                        })
                        setFormData({})
                    }).catch((e) => {
                        toast.closeAll();
                        toast({
                            status: "error",
                            title: <T>toast:title:error</T>,
                            description: getReadableApiError(e),
                        })
                    }).finally(() => setIsLoading(false))
                }
            }}
            widgets={{ AudioRecorderWidget, FileWidget: CustomFileWidget }}
            templates={{ ObjectFieldTemplate }}
        >
            <button ref={submitFormRef} type="submit" style={{ display: 'none' }} disabled={isLoading} />
        </Form>
        <HStack justify={{ base: 'center', md: 'end' }} >
            <Button
                w={{ base: 'full', md: 'auto' }}
                onClick={() => {
                    if (isPreview) {
                        onSubmit()
                    } else {
                        submitFormRef.current.click()
                    }
                }}
                isDisabled={isLoading || isDisabled}
                colorScheme="brand"

                // bg={buttonColor}
                // color={getTextColor(buttonColor)}
                mb='1' // to align properly with the form
            >
                {buttonText}
            </Button>
        </HStack>
        <PoweredBy />
    </Stack >

}

export default FormBuilt;
