import { SimpleGrid, GridItem, VStack } from "@chakra-ui/react"

const RJSFUiGrid = ({ properties, uiSchema }) => {
    return <>
        {
            uiSchema?.['ui:grid'] ? (
                <SimpleGrid columns={12} gap={{ base: 4 }}>
                    {Array.isArray(uiSchema['ui:grid']) ?
                        uiSchema['ui:grid'].map(([uiGridItemName, uiGridItemSpan]) => {
                            let element = properties.find((p => p.name === uiGridItemName))
                            if (element) {
                                return <GridItem key={element.name} colSpan={uiGridItemSpan}>
                                    {element.content}
                                </GridItem>
                            }
                            return ""
                        }) : properties.map((element) => (
                            <GridItem key={element.name} colSpan={12}>
                                {element.content}
                            </GridItem>
                        ))}
                </SimpleGrid>) :
                <VStack spacing={4} align="stretch">
                    {properties.map((prop) => prop.content)}
                </VStack>
        }
    </>
}

export default RJSFUiGrid;
